<template>
  <!--begin::Dashboard-->
  <div class="p-5 container">
    <div class="row mb-5" style="padding: 2vh;">
      <div class="col-12 col-lg-8">
        <p class="text-title" style="font-weight: 700;color: #333333;">
          {{ listData ? listData.title : "" }}
        </p>
        <div :key="videoReload">
          <div v-if="showYoutube">
          <iframe width="100%" height="400px" :src="currentYoutube"> </iframe>
        </div>
        <div v-if="!showYoutube" >
         
          <div   v-if="showVideo && listData && showSeeking == false">
            <video
            ref="videoRef"
            v-if="isFirefox == true"
            style="width: 100%;"
            @ended="updateProgress()"
            @pause="logDuration"
          >
            <source :src="currentVideo" />
          </video>
          <video
            ref="videoRef"
            v-else
            controls
            class="disable-seeking"
            style="width: 100%;"
            @ended="updateProgress()"
            @pause="logDuration"
          >
            <source :src="currentVideo" />
          </video>
          </div>
          
          <video
            ref="videoRef"
            v-else-if="showVideo && listData && showSeeking"
            controls
            style="width: 100%;"
            @ended="updateProgress()"
            @pause="logDuration"
          >
            <!-- @play="onPlay()" -->
            <!-- http://energylearning.dede.go.th/dede-cms-api/api/v1/streaming/p/Y291cnNlL2NoYXB0ZXIvNDgvY29udGVudC84MS8yMDIwMTAwM18wMTIyNDRfMTYwMTY2Mjk2NDIzOS5tcDQ=/dl/f -->
            <source :src="currentVideo" />
            <!-- <source
            :src="
              listChapter && listChapter.contentFiles
                ? imageUrl + listChapter.contentFiles[0].fUrl
                : ''
            "
            type="video/webm"
          /> -->
          </video>
          <img
            v-else
            :src="listData ? imageUrl + listData.thumbnailsPath : ''"
            alt=""
            class="w-100 mb-3"
            style="border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            object-fit: cover;
            height: 400px;border-radius: 20px;
            border: 1px solid #dbdbdb;"
          />
        </div>
        </div>
       
        <div class="row">
          <div class="col-12">
            <div
              class="d-flex justify-content-between fw-bold fs-6 text-dark w-100 mt-auto mb-2"
            >
              <span class="text-category" style="font-weight: 400;"
                >ความก้าวหน้า</span
              >
              <span>{{ listData ? listData.progressUpdate : "" }}%</span>
            </div>
            <div
              class="mx-3 w-100 rounded"
              style="background:#E1F2FF;height:21px"
            >
              <div
                class="bg-progressbar rounded "
                style="height:21px"
                data-bs-toggle="tooltip"
                :title="(listData ? listData.progressUpdate : '') + '%'"
                role="progressbar"
                :style="
                  'width: ' + (listData ? listData.progressUpdate : '') + '%'
                "
              ></div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="mt-3 text-category" style="font-weight: 700;">
              เอกสารประกอบหลักสูตร
            </div>
          </div>
          <div class="col-12 col-md-4" style="text-align: right;">
            <div style="display: flex;justify-content: flex-end;" class="mt-3">
              <p class="my-auto">แชร์ไปยัง :</p>
              <img
                @click="share('facebook')"
                src="media/icon-app/facebook.svg"
                class="mx-1"
                alt=""
              />
              <img
                @click="share('line')"
                src="media/icon-app/line.svg"
                class="mx-1"
                alt=""
              />
              <img
                @click="share('twitter')"
                src="media/icon-app/twitter.svg"
                class="mx-1 w-30px"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="my-5" v-if="showDetail">
          <div v-if="listData && listData.pdfPath">
            <div
              class="my-2"
              style="background: #FFFFFF;
                  border-radius: 10px;
                  padding: 20px;"
              v-for="(item, index) in listData.pdfPath"
              :key="index"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M24.0999 2.07202L29.6639 7.87202V29.928H8.87891V30H29.7349V7.94502L24.0999 2.07202Z"
                  fill="#909090"
                />
                <path
                  d="M24.0316 2H8.80859V29.928H29.6646V7.873L24.0316 2Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M8.65562 3.5H2.26562V10.327H22.3656V3.5H8.65562Z"
                  fill="#7A7B7C"
                />
                <path
                  d="M22.4715 10.211H2.39453V3.37903H22.4715V10.211Z"
                  fill="#DD2025"
                />
                <path
                  d="M9.05114 4.53394H7.74414V9.33394H8.77214V7.71494L8.99914 7.72794C9.21971 7.72415 9.43821 7.68463 9.64614 7.61094C9.82844 7.54824 9.99615 7.44925 10.1391 7.31994C10.2846 7.19676 10.3994 7.0413 10.4741 6.86594C10.5744 6.57444 10.6103 6.26464 10.5791 5.95794C10.5729 5.73885 10.5345 5.52186 10.4651 5.31394C10.402 5.16385 10.3083 5.02854 10.1901 4.91663C10.0718 4.80473 9.9315 4.71867 9.77814 4.66394C9.64555 4.61594 9.50856 4.5811 9.36914 4.55994C9.26357 4.54365 9.15696 4.53496 9.05014 4.53394M8.86114 6.82794H8.77214V5.34794H8.96514C9.05032 5.3418 9.13579 5.35487 9.21524 5.3862C9.29469 5.41753 9.36608 5.46631 9.42414 5.52894C9.54446 5.68995 9.60873 5.88594 9.60714 6.08694C9.60714 6.33294 9.60714 6.55594 9.38514 6.71294C9.22521 6.8009 9.04316 6.84151 8.86114 6.82794ZM12.5321 4.52094C12.4211 4.52094 12.3131 4.52894 12.2371 4.53194L11.9991 4.53794H11.2191V9.33794H12.1371C12.488 9.34756 12.8373 9.2881 13.1651 9.16294C13.4291 9.05826 13.6627 8.88952 13.8451 8.67194C14.0225 8.4524 14.1498 8.19678 14.2181 7.92294C14.2967 7.61281 14.335 7.29385 14.3321 6.97394C14.3515 6.5961 14.3223 6.21733 14.2451 5.84694C14.1719 5.5743 14.0348 5.32303 13.8451 5.11394C13.6963 4.9451 13.5142 4.80891 13.3101 4.71394C13.1349 4.63286 12.9506 4.5731 12.7611 4.53594C12.6858 4.52349 12.6095 4.51781 12.5331 4.51894M12.3511 8.45594H12.2511V5.39194H12.2641C12.4703 5.36822 12.6789 5.40542 12.8641 5.49894C12.9998 5.60727 13.1103 5.74374 13.1881 5.89894C13.2721 6.06228 13.3205 6.24155 13.3301 6.42494C13.3391 6.64494 13.3301 6.82494 13.3301 6.97394C13.3342 7.14558 13.3232 7.31724 13.2971 7.48694C13.2663 7.66117 13.2093 7.82974 13.1281 7.98694C13.0362 8.1331 12.9121 8.25625 12.7651 8.34694C12.6418 8.42674 12.4957 8.46397 12.3491 8.45294M17.4291 4.53794H14.9991V9.33794H16.0271V7.43394H17.3271V6.54194H16.0271V5.42994H17.4271V4.53794"
                  fill="#464648"
                />
                <path
                  d="M21.7807 20.255C21.7807 20.255 24.9687 19.677 24.9687 20.766C24.9687 21.855 22.9937 21.412 21.7807 20.255ZM19.4237 20.338C18.9172 20.4499 18.4236 20.6138 17.9507 20.827L18.3507 19.927C18.7507 19.027 19.1657 17.8 19.1657 17.8C19.643 18.6033 20.1984 19.3577 20.8237 20.052C20.3521 20.1223 19.8847 20.2185 19.4237 20.34V20.338ZM18.1617 13.838C18.1617 12.889 18.4687 12.63 18.7077 12.63C18.9467 12.63 19.2157 12.745 19.2247 13.569C19.1468 14.3976 18.9733 15.2143 18.7077 16.003C18.3438 15.3408 18.1555 14.5966 18.1607 13.841L18.1617 13.838ZM13.5127 24.354C12.5347 23.769 15.5637 21.968 16.1127 21.91C16.1097 21.911 14.5367 24.966 13.5127 24.354ZM25.8997 20.895C25.8897 20.795 25.7997 19.688 23.8297 19.735C23.0086 19.7218 22.1878 19.7797 21.3767 19.908C20.591 19.1164 19.9143 18.2235 19.3647 17.253C19.711 16.2524 19.9205 15.2097 19.9877 14.153C19.9587 12.953 19.6717 12.265 18.7517 12.275C17.8317 12.285 17.6977 13.09 17.8187 14.288C17.9372 15.0931 18.1608 15.8791 18.4837 16.626C18.4837 16.626 18.0587 17.949 17.4967 19.265C16.9347 20.581 16.5507 21.271 16.5507 21.271C15.5734 21.5891 14.6534 22.0618 13.8257 22.671C13.0017 23.438 12.6667 24.027 13.1007 24.616C13.4747 25.124 14.7837 25.239 15.9537 23.706C16.5753 22.9142 17.1433 22.0817 17.6537 21.214C17.6537 21.214 19.4377 20.725 19.9927 20.591C20.5477 20.457 21.2187 20.351 21.2187 20.351C21.2187 20.351 22.8477 21.99 24.4187 21.932C25.9897 21.874 25.9137 20.993 25.9037 20.897"
                  fill="#DD2025"
                />
                <path
                  d="M23.9531 2.07703V7.95003H29.5861L23.9531 2.07703Z"
                  fill="#909090"
                />
                <path d="M24.0312 2V7.873H29.6642L24.0312 2Z" fill="#F4F4F4" />
                <path
                  d="M8.97497 4.45704H7.66797V9.25704H8.69997V7.63904L8.92797 7.65204C9.14854 7.64824 9.36704 7.60873 9.57497 7.53504C9.75726 7.47231 9.92496 7.37332 10.068 7.24404C10.2124 7.12053 10.326 6.96509 10.4 6.79004C10.5003 6.49853 10.5361 6.18874 10.505 5.88204C10.4987 5.66294 10.4603 5.44596 10.391 5.23804C10.3278 5.08794 10.2342 4.95264 10.1159 4.84073C9.9976 4.72882 9.85732 4.64276 9.70397 4.58804C9.57077 4.53957 9.4331 4.5044 9.29297 4.48304C9.18739 4.46675 9.08079 4.45806 8.97397 4.45704M8.78497 6.75104H8.69597V5.27104H8.88997C8.97515 5.26489 9.06062 5.27797 9.14007 5.3093C9.21952 5.34063 9.29091 5.38941 9.34897 5.45204C9.46929 5.61305 9.53356 5.80904 9.53197 6.01004C9.53197 6.25604 9.53197 6.47904 9.30997 6.63604C9.15004 6.724 8.96799 6.76361 8.78597 6.75004M12.456 4.44404C12.345 4.44404 12.237 4.45204 12.161 4.45504L11.926 4.46104H11.146V9.26104H12.064C12.4148 9.27065 12.7641 9.21119 13.092 9.08604C13.3559 8.98136 13.5896 8.81262 13.772 8.59504C13.9493 8.37549 14.0766 8.11987 14.145 7.84604C14.2235 7.53591 14.2618 7.21695 14.259 6.89704C14.2784 6.51919 14.2491 6.14042 14.172 5.77004C14.0988 5.4974 13.9616 5.24613 13.772 5.03704C13.6232 4.8682 13.441 4.73201 13.237 4.63704C13.0617 4.55595 12.8774 4.49619 12.688 4.45904C12.6126 4.44659 12.5363 4.4409 12.46 4.44204M12.278 8.37904H12.178V5.31504H12.191C12.3971 5.29132 12.6057 5.32851 12.791 5.42204C12.9266 5.53036 13.0372 5.66684 13.115 5.82204C13.1989 5.98537 13.2473 6.16464 13.257 6.34804C13.266 6.56804 13.257 6.74804 13.257 6.89704C13.261 7.06867 13.25 7.24034 13.224 7.41004C13.1931 7.58427 13.1362 7.75284 13.055 7.91004C12.9631 8.05619 12.8389 8.17934 12.692 8.27004C12.5686 8.34983 12.4225 8.38706 12.276 8.37604M17.353 4.46104H14.923V9.26104H15.951V7.35704H17.251V6.46504H15.951V5.35304H17.351V4.46104"
                  fill="white"
                />
              </svg>
              <a :href="imageUrl + item.fileName" target="_blank"
                >&nbsp; {{ item.name }}</a
              >
            </div>
          </div>
          <div v-if="listData && listData.xlsPath">
            <div
              class="my-2"
              style="background: #FFFFFF;
                  border-radius: 10px;
                  padding: 20px;"
              v-for="(item, index) in listData.xlsPath"
              :key="index"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M19.5807 15.35L8.51172 13.4V27.809C8.51185 27.9656 8.54281 28.1205 8.60284 28.2651C8.66286 28.4097 8.75077 28.541 8.86155 28.6516C8.97233 28.7622 9.10381 28.8499 9.24848 28.9097C9.39315 28.9695 9.54818 29.0002 9.70472 29H28.8047C28.9614 29.0004 29.1167 28.9699 29.2616 28.9102C29.4065 28.8506 29.5382 28.7629 29.6492 28.6523C29.7602 28.5417 29.8483 28.4103 29.9084 28.2656C29.9686 28.1209 29.9996 27.9657 29.9997 27.809V22.5L19.5807 15.35Z"
                  fill="#185C37"
                />
                <path
                  d="M19.5807 3H9.70472C9.54818 2.99987 9.39315 3.03057 9.24848 3.09035C9.10381 3.15014 8.97233 3.23783 8.86155 3.34842C8.75077 3.45902 8.66286 3.59035 8.60284 3.73492C8.54281 3.87949 8.51185 4.03446 8.51172 4.191V9.5L19.5807 16L25.4417 17.95L29.9997 16V9.5L19.5807 3Z"
                  fill="#21A366"
                />
                <path d="M8.51172 9.5H19.5807V16H8.51172V9.5Z" fill="#107C41" />
                <path
                  opacity="0.1"
                  d="M16.4337 8.19995H8.51172V24.45H16.4337C16.7494 24.4484 17.0517 24.3225 17.2752 24.0996C17.4987 23.8766 17.6254 23.5746 17.6277 23.259V9.39095C17.6254 9.07529 17.4987 8.77327 17.2752 8.55034C17.0517 8.32741 16.7494 8.20152 16.4337 8.19995Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M15.7827 8.84998H8.51172V25.1H15.7827C16.0984 25.0984 16.4007 24.9725 16.6242 24.7496C16.8477 24.5267 16.9744 24.2246 16.9767 23.909V10.041C16.9744 9.72531 16.8477 9.4233 16.6242 9.20037C16.4007 8.97743 16.0984 8.85155 15.7827 8.84998Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M15.7827 8.84998H8.51172V23.8H15.7827C16.0984 23.7984 16.4007 23.6725 16.6242 23.4496C16.8477 23.2267 16.9744 22.9246 16.9767 22.609V10.041C16.9744 9.72531 16.8477 9.4233 16.6242 9.20037C16.4007 8.97743 16.0984 8.85155 15.7827 8.84998Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M15.1317 8.84998H8.51172V23.8H15.1317C15.4474 23.7984 15.7497 23.6725 15.9732 23.4496C16.1967 23.2267 16.3234 22.9246 16.3257 22.609V10.041C16.3234 9.72531 16.1967 9.4233 15.9732 9.20037C15.7497 8.97743 15.4474 8.85155 15.1317 8.84998Z"
                  fill="black"
                />
                <path
                  d="M3.194 8.84998H15.132C15.4482 8.84971 15.7516 8.97501 15.9755 9.19834C16.1994 9.42166 16.3255 9.72475 16.326 10.041V21.959C16.3255 22.2752 16.1994 22.5783 15.9755 22.8016C15.7516 23.0249 15.4482 23.1502 15.132 23.15H3.194C3.03738 23.1502 2.88224 23.1196 2.73746 23.0599C2.59267 23.0002 2.46108 22.9125 2.35019 22.8019C2.2393 22.6913 2.1513 22.5599 2.09121 22.4153C2.03113 22.2707 2.00013 22.1156 2 21.959V10.041C2.00013 9.88436 2.03113 9.7293 2.09121 9.58466C2.1513 9.44003 2.2393 9.30865 2.35019 9.19804C2.46108 9.08744 2.59267 8.99977 2.73746 8.94004C2.88224 8.88032 3.03738 8.84971 3.194 8.84998Z"
                  fill="url(#paint0_linear_2027_18149)"
                />
                <path
                  d="M5.69922 19.873L8.21022 15.989L5.91022 12.127H7.75722L9.01222 14.6C9.12822 14.834 9.21222 15.008 9.25022 15.124H9.26722C9.34922 14.936 9.43622 14.755 9.52722 14.578L10.8692 12.131H12.5692L10.2102 15.971L12.6292 19.876H10.8202L9.37022 17.165C9.30289 17.0484 9.24568 16.9263 9.19922 16.8H9.17522C9.13294 16.923 9.07656 17.0408 9.00722 17.151L7.51422 19.873H5.69922Z"
                  fill="white"
                />
                <path
                  d="M28.8051 3H19.5801V9.5H29.9991V4.191C29.9989 4.03438 29.968 3.87932 29.9079 3.73469C29.8478 3.59005 29.7598 3.45868 29.6489 3.34807C29.538 3.23746 29.4064 3.14979 29.2616 3.09007C29.1168 3.03034 28.9617 2.99974 28.8051 3Z"
                  fill="#33C481"
                />
                <path d="M19.5801 16H29.9991V22.5H19.5801V16Z" fill="#107C41" />
                <defs>
                  <linearGradient
                    id="paint0_linear_2027_18149"
                    x1="4.494"
                    y1="7.91398"
                    x2="13.832"
                    y2="24.086"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#18884F" />
                    <stop offset="0.5" stop-color="#117E43" />
                    <stop offset="1" stop-color="#0B6631" />
                  </linearGradient>
                </defs>
              </svg>
              <a :href="imageUrl + item.fileName" target="_blank"
                >&nbsp; {{ item.name }}</a
              >
            </div>
          </div>
          <div v-if="listData && listData.docPath">
            <div
              class="my-2"
              style="background: #FFFFFF;
                  border-radius: 10px;
                  padding: 20px;"
              v-for="(item, index) in listData.docPath"
              :key="index"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M28.8057 3H9.70472C9.54818 2.99987 9.39315 3.03057 9.24848 3.09035C9.10381 3.15014 8.97233 3.23783 8.86155 3.34842C8.75077 3.45902 8.66286 3.59035 8.60284 3.73492C8.54281 3.87949 8.51185 4.03447 8.51172 4.191V9.5L19.5807 12.75L29.9997 9.5V4.191C29.9996 4.03438 29.9686 3.87932 29.9085 3.73469C29.8484 3.59005 29.7604 3.45868 29.6495 3.34807C29.5386 3.23746 29.407 3.14979 29.2623 3.09007C29.1175 3.03034 28.9623 2.99974 28.8057 3Z"
                  fill="#41A5EE"
                />
                <path
                  d="M29.9997 9.5H8.51172V16L19.5807 17.95L29.9997 16V9.5Z"
                  fill="#2B7CD3"
                />
                <path
                  d="M8.51172 16V22.5L18.9297 23.8L29.9997 22.5V16H8.51172Z"
                  fill="#185ABD"
                />
                <path
                  d="M9.70472 29H28.8047C28.9614 29.0004 29.1167 28.9699 29.2616 28.9102C29.4065 28.8506 29.5382 28.7629 29.6492 28.6523C29.7602 28.5417 29.8483 28.4102 29.9084 28.2655C29.9686 28.1208 29.9996 27.9657 29.9997 27.809V22.5H8.51172V27.809C8.51185 27.9655 8.54281 28.1205 8.60284 28.2651C8.66286 28.4097 8.75077 28.541 8.86155 28.6516C8.97233 28.7622 9.10381 28.8499 9.24848 28.9096C9.39315 28.9694 9.54818 29.0001 9.70472 29Z"
                  fill="#103F91"
                />
                <path
                  opacity="0.1"
                  d="M16.4337 8.19995H8.51172V24.45H16.4337C16.7494 24.4484 17.0517 24.3225 17.2752 24.0996C17.4987 23.8766 17.6254 23.5746 17.6277 23.259V9.39095C17.6254 9.07529 17.4987 8.77327 17.2752 8.55034C17.0517 8.32741 16.7494 8.20152 16.4337 8.19995Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M15.7827 8.84998H8.51172V25.1H15.7827C16.0984 25.0984 16.4007 24.9725 16.6242 24.7496C16.8477 24.5267 16.9744 24.2246 16.9767 23.909V10.041C16.9744 9.72531 16.8477 9.4233 16.6242 9.20037C16.4007 8.97743 16.0984 8.85155 15.7827 8.84998Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M15.7827 8.84998H8.51172V23.8H15.7827C16.0984 23.7984 16.4007 23.6725 16.6242 23.4496C16.8477 23.2267 16.9744 22.9246 16.9767 22.609V10.041C16.9744 9.72531 16.8477 9.4233 16.6242 9.20037C16.4007 8.97743 16.0984 8.85155 15.7827 8.84998Z"
                  fill="black"
                />
                <path
                  opacity="0.2"
                  d="M15.1317 8.84998H8.51172V23.8H15.1317C15.4474 23.7984 15.7497 23.6725 15.9732 23.4496C16.1967 23.2267 16.3234 22.9246 16.3257 22.609V10.041C16.3234 9.72531 16.1967 9.4233 15.9732 9.20037C15.7497 8.97743 15.4474 8.85155 15.1317 8.84998Z"
                  fill="black"
                />
                <path
                  d="M3.194 8.84998H15.132C15.4482 8.84971 15.7516 8.97501 15.9755 9.19834C16.1994 9.42166 16.3255 9.72475 16.326 10.041V21.959C16.3255 22.2752 16.1994 22.5783 15.9755 22.8016C15.7516 23.0249 15.4482 23.1502 15.132 23.15H3.194C3.03738 23.1502 2.88224 23.1196 2.73746 23.0599C2.59267 23.0002 2.46108 22.9125 2.35019 22.8019C2.2393 22.6913 2.1513 22.5599 2.09121 22.4153C2.03113 22.2707 2.00013 22.1156 2 21.959V10.041C2.00013 9.88436 2.03113 9.7293 2.09121 9.58466C2.1513 9.44003 2.2393 9.30865 2.35019 9.19804C2.46108 9.08744 2.59267 8.99977 2.73746 8.94004C2.88224 8.88032 3.03738 8.84971 3.194 8.84998Z"
                  fill="url(#paint0_linear_1829_17895)"
                />
                <path
                  d="M6.89945 17.988C6.92245 18.172 6.93845 18.332 6.94545 18.469H6.97345C6.98345 18.339 7.00545 18.182 7.03845 17.999C7.07145 17.816 7.10045 17.661 7.12745 17.534L8.38245 12.127H10.0065L11.3065 17.453C11.3822 17.7824 11.4363 18.1165 11.4685 18.453H11.4905C11.5143 18.1254 11.5594 17.7997 11.6255 17.478L12.6645 12.12H14.1415L12.3175 19.868H10.5905L9.35345 14.742C9.31745 14.594 9.27679 14.4013 9.23145 14.164C9.18612 13.9267 9.15812 13.7533 9.14745 13.644H9.12645C9.11245 13.77 9.08445 13.957 9.04245 14.205C9.00045 14.454 8.96745 14.637 8.94245 14.757L7.77945 19.871H6.02345L4.18945 12.127H5.68945L6.82045 17.545C6.85421 17.6912 6.88058 17.8391 6.89945 17.988Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1829_17895"
                    x1="4.494"
                    y1="7.91398"
                    x2="13.832"
                    y2="24.086"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#2368C4" />
                    <stop offset="0.5" stop-color="#1A5DBE" />
                    <stop offset="1" stop-color="#1146AC" />
                  </linearGradient>
                </defs>
              </svg>
              <a :href="imageUrl + item.fileName" target="_blank"
                >&nbsp; {{ item.name }}</a
              >
            </div>
          </div>
          <div
            v-if="
              listData &&
                listData.pdfPath.length == 0 &&
                listData.xlsPath.length == 0 &&
                listData.docPath.length == 0
            "
          >
            <p class="text-category" style="color:#7F7F81">
              - ไม่พบเอกสารประกอบหลักสูตร
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <p
          class="text-title"
          style="font-style: normal;
                  font-weight: 700;
                  color: #333333;
                  "
        >
          บทเรียน
        </p>
        <div v-if="listSection">
          <div v-for="(item, index) in listSection" :key="index">
            <div class="accordion" :id="'kt_accordion_' + index">
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  :id="'kt_accordion_header_' + index"
                >
                  <button
                    class="accordion-button fs-4 fw-semibold"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#kt_accordion_body_' + index"
                    aria-expanded="true"
                    :aria-controls="'kt_accordion_body_' + index"
                    style="color:#333333;font-weight: 700;"
                  >
                    Section {{ index + 1 }} {{ item.titleSection }}
                  </button>
                </h2>
                <div
                  :id="'kt_accordion_body_' + index"
                  class="accordion-collapse collapse show"
                  :aria-labelledby="'kt_accordion_header_' + index"
                  :data-bs-parent="'#kt_accordion_' + index"
                >
                  <div class="accordion-body">
                    <div v-for="(itemC, indexC) in item.chapters" :key="indexC">
                      <div
                        style="display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;"
                      >
                        <div>
                          <i
                            v-if="itemC.flgSuccess == true || (courseRegister && courseRegister.status == 3 && courseRegister.progressUpdate == 100)"
                            class="fa fa-check-circle text-success fs-2"
                          ></i>
                          <i v-else class="fa fa-check-circle fs-2"></i>
                          {{ index + 1 }} . {{ indexC + 1 }}
                          {{ itemC.titleChapter }}
                        </div>
                        <div v-if="itemC.examId">
                          <button
                            type="button"
                            style="min-width: 160px;"
                            :disabled="
                              (itemC.flgSuccess === true || checkBeforSuccess(index, indexC)) || courseRegister.progressUpdate == 100"
                            @click="
                              getExam(
                                item.id,
                                itemC.chapterId,
                                itemC.examId,
                                itemC.flgPretest
                              )
                            "
                            class="btn btn-default my-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="16"
                              viewBox="0 0 13 16"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12.5733 4.92308L7.59557 0H1.68446C1.26964 0 0.906686 0.153845 0.595573 0.461538C0.28446 0.769232 0.128906 1.1282 0.128906 1.53846V14.4615C0.128906 14.8718 0.28446 15.2308 0.595573 15.5385C0.906686 15.8462 1.26964 16 1.68446 16H11.0178C11.4326 16 11.7956 15.8462 12.1067 15.5385C12.4178 15.2308 12.5733 14.8718 12.5733 14.4615V4.92308ZM11.3282 5.54011H6.97266V1.23242L11.3282 5.54011Z"
                                fill="#323563"
                              />
                            </svg>
                            ทำข้อสอบ

                            <!-- <i class="las la-file-alt"></i> -->
                            <!-- {{ itemC.titleChapter }} -->
                          </button>
                        </div>
                        <div v-else-if="itemC.videoPath">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            :disabled="checkBeforSuccess(index, indexC)"
                            @click="videoExam(item.id, itemC.chapterId, true)"
                            class="btn btn-default my-2"
                            style="min-width: 160px;"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="16"
                              viewBox="0 0 14 16"
                              fill="none"
                            >
                              <path
                                d="M12.9531 7.04167C13.2587 7.29167 13.4115 7.61111 13.4115 8C13.4115 8.38889 13.2587 8.70833 12.9531 8.95833L1.36979 15.875C1.20312 15.9583 1.05035 16 0.911458 16C0.689235 16 0.494792 15.9167 0.328125 15.75C0.161458 15.5833 0.078125 15.3889 0.078125 15.1667V0.833333C0.078125 0.61111 0.161458 0.416667 0.328125 0.25C0.494792 0.0833325 0.689235 0 0.911458 0C1.07813 0 1.2309 0.0416663 1.36979 0.125L12.9531 7.04167Z"
                                fill="#323563"
                              />
                            </svg>
                            วิดิโอ
                          </button>
                        </div>
                        <div v-else-if="itemC.docPath">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            :disabled="checkBeforSuccess(index, indexC)"
                            @click="
                              openFile(
                                item.id,
                                itemC.chapterId,
                                itemC.docPath,
                                'doc'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          ></button>
                        </div>
                        <div v-else-if="itemC.pdfPath">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            :disabled="checkBeforSuccess(index, indexC)"
                            @click="
                              openFile(
                                item.id,
                                itemC.chapterId,
                                itemC.pdfPath,
                                'pdf'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1174_14950)">
                                <path
                                  d="M5.99325 6.95486C5.99325 7.33246 6.14325 7.69459 6.41025 7.96159C6.67726 8.22859 7.03939 8.37859 7.41698 8.37859C7.79458 8.37859 8.15671 8.22859 8.42371 7.96159C8.69071 7.69459 8.84071 7.33246 8.84071 6.95486C8.84071 6.57726 8.69071 6.21513 8.42371 5.94813C8.15671 5.68113 7.79458 5.53113 7.41698 5.53113C7.03939 5.53113 6.67726 5.68113 6.41025 5.94813C6.14325 6.21513 5.99325 6.57726 5.99325 6.95486ZM12.9365 6.62689C11.7314 4.08833 9.90978 2.81079 7.46783 2.81079C5.02461 2.81079 3.20427 4.08833 1.99919 6.62816C1.95085 6.73052 1.92578 6.8423 1.92578 6.95549C1.92578 7.06869 1.95085 7.18047 1.99919 7.28282C3.20427 9.82138 5.02588 11.0989 7.46783 11.0989C9.91105 11.0989 11.7314 9.82138 12.9365 7.28155C13.0344 7.07562 13.0344 6.83664 12.9365 6.62689ZM7.41698 9.19215C6.18139 9.19215 5.17969 8.19045 5.17969 6.95486C5.17969 5.71927 6.18139 4.71757 7.41698 4.71757C8.65258 4.71757 9.65427 5.71927 9.65427 6.95486C9.65427 8.19045 8.65258 9.19215 7.41698 9.19215Z"
                                  fill="#323563"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1174_14950">
                                  <rect
                                    width="13.0169"
                                    height="13.0169"
                                    fill="white"
                                    transform="translate(0.958984 0.446289)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            เปิดเนื้อหาออนไลน์
                          </button>
                        </div>
                        <div v-else-if="itemC.xlsPath">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            :disabled="checkBeforSuccess(index, indexC)"
                            @click="
                              openFile(
                                item.id,
                                itemC.chapterId,
                                itemC.xlsPath,
                                'xls'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          ></button>
                        </div>
                        <div v-else-if="itemC.youtube">
                          <!-- <p>{{ itemC.titleChapter }}</p> -->
                          <button
                            type="button"
                            :disabled="checkBeforSuccess(index, indexC)"
                            @click="
                              openFile(
                                item.id,
                                itemC.chapterId,
                                itemC.youtube,
                                'youtube'
                              )
                            "
                            class="btn btn-default w-100 my-2"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="kt_modal_score" ref="newTargetModalRef">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <h3 class="modal-title" style="text-align:center">ให้คะแนนประเมิน</h3>
          <div
            class="my-2"
            style="display: flex;
       justify-content: space-between;
    flex-wrap: wrap;"
          >
            <span>เนื้อหา</span>
            <Rating
              :value="rate1"
              v-model="rate1"
              :max="5"
              :form="true"
              @input="handleData1"
            />
          </div>
          <div
            class="my-2"
            style="display: flex;
        justify-content: space-between;
    flex-wrap: wrap;"
          >
            <span>รูปแบบการสอน</span>
            <Rating
              :value="rate2"
              v-model="rate2"
              :max="5"
              :form="true"
              @input="handleData2"
            />
          </div>
          <div
            style="display: flex;
        justify-content: space-between;
    flex-wrap: wrap;"
          >
            <span>ระยะเวลา</span>
            <Rating
              :value="rate3"
              v-model="rate3"
              :max="5"
              :form="true"
              @input="handleData3"
            />
          </div>
          <div
            class="my-2"
            style="display: flex;
       justify-content: space-between;
    flex-wrap: wrap;"
          >
            <span>ผลที่ได้รับ</span>
            <Rating
              :value="rate4"
              v-model="rate4"
              :max="5"
              :form="true"
              @input="handleData4"
            />
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
            style="border-radius: 29px"
          >
            ปิด
          </button>
          <button
            ref="btn_sentScore"
            type="button"
            class="btn "
            style="color: var(--ffffff, #FFF);border-radius: 29px;background: var(--323563, #323563);"
            @click="sentScore"
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Rating from "../Rating.vue";
import { Modal } from "bootstrap";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
const videoReload = ref(0);
const showSeeking = ref(false);
const currentVideo = ref();
const currentYoutube = ref();
const listData = ref();
const listSection = ref();
const videoRef = ref();
const currentChId = ref();
const showDetail = ref(true);
const showDocument = ref(false);
const showBookmark = ref(false);
const listDocument = ref();
const showYoutube = ref(false);
const showVideo = ref(false);
const currentUpdate = ref({
  sectionId: "",
  chapterId: "",
  flgSuccess: false,
});
const currentDuration = ref(0);
const courseRegister = ref();
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "mandatory-learn",
  components: {
    Rating,
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      rateId: null,
      rate1: 0,
      rate2: 0,
      rate3: 0,
      rate4: 0,
      showModal: false,
      isFirefox: false,
    };
  },
  setup() {
    /* eslint-disable */

    const router = useRouter();
    const route = useRoute();
    onMounted(() => {
      getDataList();
      // getDocuments();
      // getBookmark();
    });
    watch(
      () => route.path,
      () => {
        nextTick(() => {
          getDataList();
        });
      }
    );
    const getDataList = async () => {
      const getSyllabus = await axios.get(
        process.env.VUE_APP_API_URL + "/getSyllabus?id=" + route.params.sbId,
        {
          headers: {
            token: localStorage.getItem("id_token")
          },
        }
      );
      showVideo.value = false;
      axios
        .get(
          process.env.VUE_APP_API_URL + "/getCourse?id=" + route.params.cId,
          // "&categories=" +
          // categories.value
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.content[0]);
          listData.value = response.data.data.content[0];
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getChapterList();
          setCurrentPageBreadcrumbs(listData.value.title, [
            getSyllabus.data.data.content[0].name,
          ]);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getChapterList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourseSection?courseId=" +
            route.params.cId,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.content);
          listSection.value = response.data.data.content;
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          // videoExam(listData.value.chapter.chapterId, false);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getDocuments = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/courses/" +
            route.params.id +
            "/documents",
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          listDocument.value = response.data.result[0].downloadFiles;
          // console.log(response.data.result[0].downloadFiles);
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const getBookmark = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/v1/courses/" +
            route.params.id +
            "/bookmark",
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((response) => {
          // console.log(response.data.result);
          if (response.data.result.length > 0) {
            showBookmark.value = true;
          } else {
            showBookmark.value = false;
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const videoExam = async (secId, chId, autoPlay) => {
      // console.log(videoExam);
      currentUpdate.value.sectionId = secId;
      currentUpdate.value.chapterId = chId;
      showVideo.value = true;
      showYoutube.value = false;
      currentChId.value = chId;
      let flgSuccess = false;
      for (const loopSection of listSection.value) {
        if (secId == loopSection.id) {
          for (const loopChapter of loopSection.chapters) {
            if (loopChapter.chapterId == chId) {
              // console.log(loopChapter);
              currentVideo.value =
                process.env.VUE_APP_IMG_URL + loopChapter.videoPath;
                if (loopChapter.flgSuccess == true) {
                  flgSuccess = loopChapter.flgSuccess ; 
                }else{
                  if (loopChapter.flgSuccess == true || (courseRegister.value && courseRegister.value.status == 3 && courseRegister.value.progressUpdate == 100)) {
                    flgSuccess = true ; 
                  }else{
                    flgSuccess = loopChapter.flgSuccess ; 
                  }
                }
              currentUpdate.value.flgSuccess = flgSuccess;
              showSeeking.value = flgSuccess;
              videoReload.value += 1;
            }
          }
        }
      }
      if (flgSuccess != true) {
        let currentTime = await axios.get(
          process.env.VUE_APP_API_URL +
            `/getCourseVideoProgress?courseId=${route.params.cId}&sectionId=${secId}&chapterId=${chId}`,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        );
        if (currentTime.data.data.length > 0) {
          videoRef.value.currentTime = currentTime.data.data[0].elapsed;
        }
      }
      if (autoPlay == true ) {
        setTimeout(() => {
          videoRef.value.play();
        }, 1000);
      }
    };
    const getExam = (secId, chId, exId, pretest) => {
      console.log(pretest);
      Swal.fire({
        title: "แบบทดสอบก่อนเรียน",
        text:
          "ระบบจะจับเวลาและบันทึกการทำข้อสอบทันที เมื่อคุณกดปุ่มเริ่มทำข้อสอบ",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#323563",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
        confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
        // cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.setItem("reloaded", "1");
          router.push({
            name: "mandatory-exam",
            params: {
              sbId: route.params.sbId,
              cId: route.params.cId,
              secId: secId,
              chId: chId,
              exId: exId,
              type: pretest == true? 'pretest' : "posttest" ,
            },
          });
        }
      });
    };
    // const postExam = (chId, exId) => {
    //   Swal.fire({
    //     title:
    //       "ระบบจะจับเวลาและบันทึกการทำข้อสอบทันที เมื่อคุณกดปุ่มเริ่มทำข้อสอบ",
    //     text:
    //       "เกณฑ์การตัดสิน : คุณต้องได้คะแนนสอบมากกว่า 60% และทำข้อสอบได้ไม่เกิน 3 ครั้ง หากไม่ผ่าน ความคืบหน้าในบทเรียนนั้นจะถูกรีเซ็ตเป็น 0",
    //     icon: "warning",
    //     showCancelButton: false,
    //     confirmButtonColor: "#323563",
    //     cancelButtonColor: "#d33",
    //     allowOutsideClick: false,
    //     confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
    //     // cancelButtonText: "ยกเลิก",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       router.push({
    //         name: "voluntary-exam",
    //         params: {
    //           cId: route.params.id,
    //           chId: chId,
    //           exId: exId,
    //           type: "posttest",
    //         },
    //       });
    //     }
    //   });
    // };
    const detail = () => {
      showDetail.value = true;
      showDocument.value = false;
    };
    const document = () => {
      showDetail.value = false;
      showDocument.value = true;
    };
    const bookmark = () => {
      const formData = new FormData();
      formData.append("syllabusId", "-1");
      formData.append(
        "bookmark",
        showBookmark.value == true ? "false" : "true"
      );
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/api/v1/courses/" +
            route.params.id +
            "/chapter/" +
            currentChId.value +
            "/bookmark",
          formData,
          {
            headers: {
              token: localStorage.getItem("id_token")
            },
          }
        )
        .then((res) => {
          getBookmark();
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const share = (social) => {
      const url = new URL(window.location.href);
      if (social == "facebook") {
        window.open(
          "http://facebook.com/sharer/sharer.php?u=" +
            encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      } else if (social == "twitter") {
        window.open(
          "http://www.twitter.com/share?url=" + encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      } else {
        window.open(
          "https://social-plugins.line.me/lineit/share?url=" +
            encodeURIComponent(url.href),
          "",
          "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
        );
      }
    };
    const openFile = (secId, chId, file, type) => {
      currentUpdate.value.sectionId = secId;
      currentUpdate.value.chapterId = chId;
      if (type == "youtube") {
        showVideo.value = false;
        showYoutube.value = true;
        // https://www.youtube.com/embed/
        let idYoutub = file.split("v=")[1];
        currentYoutube.value = "https://www.youtube.com/embed/" + idYoutub;
      } else {
        window.open(process.env.VUE_APP_IMG_URL + file, "_blank");
      }
    };
    const updateProgress = async () => {
      const formData = new FormData();
      if (listData && currentUpdate.value.flgSuccess == false) {
        formData.append("courseId", route.params.cId + "");
        formData.append("sectionId", currentUpdate.value.sectionId);
        formData.append("chapterId", currentUpdate.value.chapterId);
        // console.log("update");
        await axios
          .post(process.env.VUE_APP_API_URL + "/updateProgress", formData, {
            headers: {
              token: localStorage.getItem("id_token")
            },
          })
          .then(async (res) => {
            if (res.data.code == 200) {
              const getCourseRegister = await axios.get(
                process.env.VUE_APP_API_URL +
                  "/getCourseRegister?userId=" +
                  localStorage.getItem("u_id") +
                  "&courseId=" +
                  route.params.cId,
                {
                  headers: {
                    token: localStorage.getItem("id_token")
                  },
                }
              );
              if ( getCourseRegister.data.data.content.length > 0 && 
                getCourseRegister.data.data.content[0].progressUpdate == 100
              ) {
                router.go(0);
              } else {
                getDataList();
              }
            } else {
              if (res.data.message != "คอร์สนี้ท่านอยู่ในสถานะจบหลักสูตรแล้ว") {
                Swal.fire({
                  title: "แจ้งเตือนข้อผิดพลาด",
                  text: res.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              }
            }
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      }
    };
    const seekingDisable = (event) => {
      // console.log(event);
    };
    return {
      getDataList,
      listData,
      listSection,
      getChapterList,
      getBookmark,
      getDocuments,
      videoRef,
      getExam,
      videoExam,
      currentChId,
      showDetail,
      showDocument,
      showBookmark,
      detail,
      document,
      bookmark,
      listDocument,
      share,
      currentVideo,
      openFile,
      showYoutube,
      currentYoutube,
      showVideo,
      updateProgress,
      currentUpdate,
      currentDuration,
      seekingDisable,
      videoReload,
      showSeeking,
      courseRegister
    };
  },
  async mounted() {
    this.isFirefox = navigator.userAgent.toLowerCase().includes("firefox");
    const getCourseRegister = await axios.get(
      process.env.VUE_APP_API_URL +
        "/getCourseRegister?userId=" +
        localStorage.getItem("u_id") +
        "&courseId=" +
        this.$route.params.cId,
      {
        headers: {
          token: localStorage.getItem("id_token")
        },
      }
    );
    courseRegister.value = getCourseRegister.data.data.content[0];
    if ( getCourseRegister.data.data.content.length > 0 && 
      getCourseRegister.data.data.content[0].rate == null &&
      getCourseRegister.data.data.content[0].progressUpdate == 100
    ) {
      Swal.fire({
        title: "ยินดีด้วย!",
        text: "คุณได้เรียนจบหลักสูตรแล้ว",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "ตกลง",
        showCancelButton: true,
        cancelButtonText: "ให้คะแนน",
        customClass: {
          confirmButton: "btn btn-swal1",
          cancelButton: "btn btn-swal2",
        },
      }).then(async (res) => {
        if (res.isConfirmed != true) {
          this.clickScore(getCourseRegister.data.data.content[0].id);
        }
      });
    }
  },
  methods: {
    onPlay() {
      // axios
      //   .post(
      //     process.env.VUE_APP_API_URL +
      //       "/api/v1/courses/" +
      //       this.$route.params.id +
      //       "/chapters/" +
      //       currentChId.value +
      //       "/progress/content/exam/0/score/0",
      //     {},
      //     {
      //       headers: headers,
      //     }
      //   )
      //   .then((res) => {
      // console.log(res);
      //   })
      //   .catch((error) => {
      // console.log(error);
      //   });
    },
    async logDuration() {
      let flgSuccess = false;
      for (const loopSection of listSection.value) {
        if (currentUpdate.value.sectionId == loopSection.id) {
          for (const loopChapter of loopSection.chapters) {
            if (loopChapter.chapterId == currentUpdate.value.chapterId) {
              flgSuccess = loopChapter.flgSuccess;
            }
          }
        }
      }
      const data = {
        duration: videoRef.value.duration,
        elapsed: videoRef.value.currentTime,
        remaining: videoRef.value.duration - videoRef.value.currentTime,
        scoreAverage:
          (videoRef.value.currentTime / videoRef.value.duration) * 100,
      };
      const formData = new FormData();
      formData.append("courseId", this.$route.params.cId + "");
      formData.append("sectionId", currentUpdate.value.sectionId);
      formData.append("chapterId", currentUpdate.value.chapterId);
      formData.append("duration", data.duration);
      formData.append("elapsed", data.elapsed);
      if (flgSuccess == false) {
        // console.log("update");
        await axios
          .post(
            process.env.VUE_APP_API_URL + "/updateVideoProgress",
            formData,
            {
              headers: {token: localStorage.getItem("id_token")},
            }
          )
          .then((res) => {
            // console.log(res);
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: error.response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          });
      }
    },
    formatDate(date) {
      const event = new Date(date);
      return event.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    formatDateTime(date) {
      const event = new Date(date);
      return (
        event.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }) + " น."
      );
    },
    handleData1(data) {
      this.rate1 = data;
    },
    handleData2(data) {
      this.rate2 = data;
    },
    handleData3(data) {
      this.rate3 = data;
    },
    handleData4(data) {
      this.rate4 = data;
    },
    async sentScore() {
      const formData = new FormData();
      formData.append("id", this.rateId + "");
      formData.append("rate1", this.rate1 + "");
      formData.append("rate2", this.rate2 + "");
      formData.append("rate3", this.rate3 + "");
      formData.append("rate4", this.rate4 + "");
      formData.append("remark", "");

      await axios
        .post(process.env.VUE_APP_API_URL + "/updateCourseRate", formData, {
          headers: { token: localStorage.getItem("id_token") },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Swal.fire({
              title: "บันทึกรายการสำเร็จ",
              text: "รายการข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "ตกลง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-primary",
              },
            }).then(() => {
              this.$router.go(0);
            });
          } else {
            Swal.fire({
              title: "แจ้งเตือนข้อผิดพลาด",
              text: res.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
              customClass: {
                confirmButton: "btn fw-semobold btn-light-danger",
              },
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "แจ้งเตือนข้อผิดพลาด",
            text: error.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "กรุณาลองใหม่ภายหลัง!",
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });
    },
    async clickScore(rateId) {
      this.rateId = rateId;
      this.rate1 = 0;
      this.rate2 = 0;
      this.rate3 = 0;
      this.rate4 = 0;
      const modal_score = document.getElementById("kt_modal_score")!;
      const modal = new Modal(modal_score);
      modal.show();
      modal.hide();
    },
    checkBeforSuccess(indexS, indexC) {
      // console.log("checkBeforSuccess : " + indexS, indexC);
      if (indexS > 0) {
        if (indexC > 0) {
          if (indexC == 0) {
            if (listSection.value[indexS - 1].chapters[
              listSection.value[indexS - 1].chapters.length - 1
            ].flgSuccess == false &&  courseRegister.value.progressUpdate != 100) {
              return true
            }else{
              
              return false
            }
            
            // return listSection.value[indexS - 1].chapters[
            //   listSection.value[indexS - 1].chapters.length - 1
            // ].flgSuccess == false
            //   ? true
            //   : false;
          } else {
            if (listSection.value[indexS].chapters[
              listSection.value[indexS].chapters.length - 1
            ].flgSuccess == false &&  courseRegister.value.progressUpdate != 100) {
              return true
            }else{
              return false
            }
            // return listSection.value[indexS].chapters[
            //   listSection.value[indexS].chapters.length - 1
            // ].flgSuccess == false
            //   ? true
            //   : false;
          }
        } else {
          if (listSection.value[indexS - 1].chapters[
              listSection.value[indexS - 1].chapters.length - 1
            ].flgSuccess == false &&  courseRegister.value.progressUpdate != 100) {
              return true
            }else{
              return false
            }
          // return listSection.value[indexS - 1].chapters[
          //   listSection.value[indexS - 1].chapters.length - 1
          // ].flgSuccess == false
          //   ? true
          //   : false;
        }
      } else {
        if (indexC > 0) {
          if (listSection.value[indexS].chapters[indexC - 1].flgSuccess == false &&  courseRegister.value.progressUpdate != 100) {
              return true
            }else{
              return false
            }
          // return listSection.value[indexS].chapters[indexC - 1].flgSuccess ==
          //   false
          //   ? true
          //   : false;
        } else {
          return false;
        }
      }
    },
  },
});
</script>
<style>
.btn-default {
  background: #ffffff;
  border: 1px solid #323563 !important;
  color: #323563 !important;
  border-radius: 10px;
}
.btn-lern {
  background: #ffffff;
  border: 1px solid #323563 !important;
  color: #323563 !important;
  border-radius: 21px;
}
.btn-complete {
  background: #7f7f81;
  border: 1px solid #7f7f81;
  color: #ffffff;
  border-radius: 10px;
}

.btn-register {
  background: #f2a17d;
  color: #ffffff;
  width: 70%;
  border-radius: 21px;
}
</style>
